import { Card, FlexBox, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Title } from './title'

export interface Props {}

export const BrandPreviewFragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <>
      Hello from Brand preview fragment
    </>
  )
}
